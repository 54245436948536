










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsSearch extends Vue {
  private searchKey: string = '';
  show:boolean = false;

  search () {
    this.show = false;
    this.$router.push({ path: `/product/search/${this.searchKey}` });
    this.searchKey = '';
  }
}
